<template>
	<div class="business">
		<banner :picS="bannerS"></banner>
		<div class="widthBox">
			<div class="pageTitle">
				<titleOne id="business_1" en='business college' title="滋瑞生商学院"></titleOne>
			</div>
			<div class="picS">
				<banner itemType="school" :picS="pageData.school" :navigation="false"></banner>
			</div>
		</div>
		<div class="xiang"></div>
		<div class="widthBox">
			<div class="pageTitle">
				<titleOne id="business_2" en='Course to share' title="课程分享"></titleOne>
			</div>
			<ul class="lists">
				<li v-for="item in pageData.curriculum">
					<video controls class="pic" :poster="item.back_img" :src="item.video"></video>
					<!-- <img :src="item.back_img" alt="" class="pic"> -->
				</li>
			</ul>
		</div>
	</div>
</template>

<script>
	import titleOne from '@/components/titleOne.vue'
	import banner from '@/components/banner.vue'
	export default {
		name: 'business',
		components: {
			banner,
			titleOne
		},
		computed: {
			bannerS() {
				return this.$store.state.bannerS
			},
		},
		data: function() {
			return {
				pageData: {}
			};
		},
		mounted() {
			this.$nextTick(function(){
				if(this.$route.query.to != undefined || this.$route.query.to != null){
					setTimeout(()=>{
						document.getElementById(this.$route.query.to).scrollIntoView()
					},500)
				}else{
					document.body.scrollIntoView()
				}
			})
		},
		created() {
			this.$api.get('home/index/businessSchool', {}).then(res => {
				this.pageData = res.data
			})
		}
	}
</script>

<style lang="scss" scoped="scoped">
	.business {
		.pageTitle {
			margin-top: 70px;
		}

		.picS {
			margin-top: 50px;
		}

		.xiang {
			width: 100%;
			height: 2px;
			background: #1D2A55;
			margin-top: 80px;
		}

		.lists {
			li {
				margin-top: 50px;
			}

			.pic {
				width: 100%;
				background:#000;
				height:600px;
			}
		}
	}
	@media screen and(max-width:768px) {
		.business{
			.pageTitle{
				margin-top:20px;
			}
			.picS{
				margin-top:20px;
			}
			.xiang{
				margin-top:20px;
			}
			.lists{
				li{
					margin-top:20px;
					.pic{
						height:240px;
					}
				}
			}
		}
		
	}
</style>
